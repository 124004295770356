import "./App.scss";
//import Draggable from "./Draggable";
import { Router } from "@reach/router";
import { Suspense } from "react";
import Scene10 from "./Scene10";

function App() {
  return (
    <Suspense fallback={null}>
      <Router style={{ height: "100%" }}>
        <Scene10 path="/" />
      </Router>
    </Suspense>
  );
}

export default App;
