// a react-three-fiber scene
import { OrbitControls } from "@react-three/drei";
import { softShadows } from "@react-three/drei";

import React from "react";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";


import Wagen from "./Wagen";
softShadows();

export default function Scene10() {
  return (
    <div style={{ height: "100%" }}>
      <Suspense falllback={null}>
        <Canvas
          draggable
          orthographic
          shadows
          camera={{ position: [-5, 5, -5], zoom: 15, near: -20 }}
        >
          <OrbitControls minPolarAngle={0.3} maxPolarAngle={1.2} minZoom={1} maxZoom={40} />
          <Wagen scale={0.1} position={[0,-3,0]} />
          <ambientLight intensity={2}></ambientLight>
        </Canvas>
      </Suspense>
    </div>
  );
}