/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import * as THREE from "three";

export default function Model(props) {
  const group = useRef()
  const { nodes, animations } = useGLTF('/wagen.glb', "https://www.gstatic.com/draco/versioned/decoders/1.4.1/")
  console.log(animations)
  animations[0].name = "test";
  const { actions } = useAnimations(animations, group)
  console.log(actions)
  let audio = useMemo(() => new Audio("/squeek.wav"), [])
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        onClick={() => {
          audio.play(); actions.test.reset(); actions.test.timeScale = 1;
          actions.test.setLoop(THREE.LoopOnce);
          actions.test.play()
        }}
        name="WAGEN"
        geometry={nodes.WAGEN.geometry}
        material={nodes.WAGEN.material}
        position={[73.98, 79.2, 0.49]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.RADEN.geometry}
        material={nodes.RADEN.material}
        position={[1.89, 24.86, 0.95]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.WAGEN001.geometry}
        material={nodes.WAGEN001.material}
        position={[73.98, 79.2, 0.49]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
    </group>
  )
}
